import { Injectable } from '@angular/core';
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  OAuthProvider
} from "@firebase/auth";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {Router} from "@angular/router";
import {initializeApp} from "@angular/fire/app";
import {environment} from "@environments/environment";
import {sendPasswordResetEmail} from "@angular/fire/auth";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {serverTimestamp} from 'firebase/firestore';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})

export class UserService {
  userId:any;
  private baseUrl = 'https://us-central1-generalpurpose-361522.cloudfunctions.net/api/users';

  constructor(public angularFireAuth: AngularFireAuth,
              private router: Router,
              private db: AngularFirestore,
              private http: HttpClient
            ) {}

  async userValue () {
    const app = await initializeApp(environment.firebaseConfig);
    const auth = await getAuth(app);
    return new Promise((resolve, reject) => {
      try {
        onAuthStateChanged(auth, user => {
          resolve(user);
        });
      } catch {
        reject('api failed');
      }
    });
  }

  async SignInGoogle() {
    const googleAuth = new GoogleAuthProvider();
    await this.angularFireAuth.signInWithPopup(googleAuth);
    await this.storeUser();
    this.router.navigate(['/account']).then(r => {
    });
  }

  async storeUser() {
    const currentUser = await this.angularFireAuth.currentUser;
    if (currentUser) {
      const user =  await this.getUserByMail(currentUser.email);
      if(user && !user[0]){
        await this.db.collection('users').add({
          accountType: "free",
          accountTypeName: "フリーアカウント",
          active: true,
          createAt: serverTimestamp(),
          email: currentUser.email,
          photoURL: '',
          purchaseDate: serverTimestamp(),
          uid: currentUser.uid,
          displayName: '',
          updateAt: serverTimestamp(),
          tokens: [],
          purchaseOS: null,
        });
      }
    }
  }

  async register(email: string, password: string)
  {
    await this.angularFireAuth.createUserWithEmailAndPassword(email, password);
    await this.storeUser();
    await this.sendMailRegisterAccount(email);
  }

  async SignOut() {
    await this.angularFireAuth.signOut();
  }

  async forgotPassword(email: string) {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email);
  }

  async updatePassword(oldPassword : string, newPassword : string) {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const credential = await EmailAuthProvider.credential(
        user.email ? user.email : '',
        oldPassword,
      );
      await reauthenticateWithCredential(
        auth.currentUser,
        credential
      )
      await updatePassword(user, newPassword);
    }
  }

  async deleteAccount(id:string) {
    await this.db.collection('users').doc(id).update({
      active:false,
      tokens:[],
    })
  }

  async SignInApple() {
    let provider = new OAuthProvider('apple.com');
    await this.angularFireAuth.signInWithPopup(provider);
    await this.storeUser();
    this.router.navigate(['/account']).then(r => {
    });
  }

  async update(customer : string, email : string) {

    const userId = await this.getDocId(email);
    await this.db.collection('users').doc(userId).update(
      {
        "payment_method": "stripe",
        "stripe_cus": customer,
      });
  }

  async getDocId(email: any) {
    return new Promise<any>((resolve, reject)=> {
      try {
        this.db.collection('users', ref => ref.where('email', '==', email)).snapshotChanges().subscribe((res: any) =>  {
          resolve(res[0].payload.doc.id);
        });
      } catch (e) {
        reject('api fail');
      }
    })
  }

  async updateStripeSub(sub: string,email: string, planName: string, accountType: string) {
    let id = await this.getDocId(email);
    await this.db.collection('users').doc(id).update({
      "accountTypeName": accountType,
      "accountType": planName,
      "sub": sub,
      "purchaseDate": serverTimestamp(),
    });
  }

  async getUserByMail(email: any) {
    return new Promise<any>((resolve) => {
      this.db.collection('users', ref => ref.where('email', '==', email)).valueChanges({ idField: 'id' }).subscribe((user) => {
          resolve(user)
      })
    })
  }

  async changeUsername(username: string, email: string)
  {
    const userId = await this.getDocId(email);

    await this.db.collection('users').doc(userId).update(
      {
        "displayName": username,
      }
    );
  }

  async sendMailRegisterAccount(email: string) {
    await this.db.collection("mail").add({
      "to": email,
      "message": {
        "subject": "汎用機能: サインアップありがとうございました",
        "html": `
<p>generalpurposeサービスにサインアップをありがとうございます。</p>
<p>ログインは <a href="https://generalpurpose.pacenotes.io/" target="_blank"> こちら </a> のアドレスからご利用ください。</p>
<p>generalpurpose開発チーム</p>
`,
      },
    })
  }
  async updateStatusUser(id?: string, status?: boolean) {
    return new Promise<void>((resolve, reject) => {
      try {
        const user = {
          disabled: status
        };
        this.http.patch(`${this.baseUrl}/${id}`, user).subscribe(res => {

          resolve();
        })
      } catch {
        reject('api failed');
      }
    });
  }

}
