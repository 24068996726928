import { Component, OnInit } from '@angular/core';
import {UserService, AlertService, StripeService, ValidationFormsService, PriceService} from '@app/services';
import {Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {environment} from "@environments/environment";
import {getAuth} from "@firebase/auth";
import {initializeApp} from "@angular/fire/app";
@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {
  mail:any;
  customer:any;
  user:any;
  subId:string='';
  baseUrl:string = environment.register_url_stripe_subscription;
  delSubUrl:string = environment.del_url_stripe_subscription;
  accountType: string = 'free';
  isDisableButton:boolean =false;
  purchaseDate!: Date;
  expirationDate: any;
  currentSubId:string = '';
  prices:any;
  default_payment_method:string = '';
  constructor(private userService: UserService,
              private router: Router,
              private AlertService: AlertService,
              public angularFireAuth: AngularFireAuth,
              public stripeService: StripeService,
              private priceService:PriceService
              )
  {}

  async ngOnInit() {
    const app = await initializeApp(environment.firebaseConfig);
    const currentUser = await getAuth(app).currentUser;
    if (currentUser) {
      this.mail = currentUser.email;
      const user = await this.userService.getUserByMail(this.mail);
      if(user && user[0]){
        this.user = user[0];
        this.accountType = this.user.accountType;
        this.purchaseDate = this.user.purchaseDate.toDate();
        this.currentSubId = this.user.sub ? this.user.sub : '';
      }
      if(this.user.stripe_cus){
        this.getCustomer(this.user.stripe_cus);
      }
      let year = this.purchaseDate.getFullYear();
      let nextMonth = this.purchaseDate.getMonth() + 2;
      let day = this.purchaseDate.getDate();
      this.expirationDate = year + '/' + nextMonth + '/' + day;
    }
    this.getListPrice();
  }
  getCustomer(customerId: string) {
    const headers = {'content-type': 'application/json'}
    const urlCustomer = environment.customer_stripe + customerId;
    this.stripeService.getCustomer(urlCustomer, headers).toPromise().then((data: any) => {
      this.default_payment_method = data.customer.invoice_settings.default_payment_method == null?'':data.customer.invoice_settings.default_payment_method;
    })
  }
  getListPrice(){
    this.priceService.getAll().subscribe((price)=>{
      this.prices = price;
    })
  }

  async buyPlan(productId:string, planName:string) {
    this.isDisableButton = true;
    this.AlertService.clear();
    try {
      if (this.user && this.user.payment_method == '' || this.user && !this.user.payment_method ||this.default_payment_method == '' ) {
        this.router.navigate(['payment-method']).then();
      }
      if(this.user && this.user.payment_method == 'stripe' && this.user.stripe_cus !== '') {
        const headers = {'content-type': 'application/json'}
        const body = {
          "uid":this.user.stripe_cus,
          "product_id":productId
        };

        if(this.currentSubId != ''){
          // cancel current sub in stripe
          const cancelBody = {
            "subId":this.currentSubId
          };
          await this.stripeService.delStripeSubscription(this.delSubUrl,cancelBody,headers).toPromise().then((data: any) => {
            this.currentSubId = '';
          });
        }
        const card = await this.stripeService.stripeSubscription(this.baseUrl,body,headers).toPromise().then((data: any) => {
          this.subId = data.id  ;
          this.currentSubId = data.id;
        });
        //todo register
       let accountType = '';
       switch (planName) {
         case 'basic':
           accountType = "ベーシック";
           break;
         case 'free':
           accountType = "フリーアカウント";
           break;
         case 'standard':
           accountType = "スタンダード";
           break;
         case 'premium':
           accountType = "プレミアム";
           break;

       }
        await this.userService.updateStripeSub(this.subId, this.mail, planName, accountType);
        this.showAlert('プランをオーダーしました。',"success")
        this.accountType = planName;
        this.isDisableButton = false;
      }
    } catch (e) {
      this.isDisableButton = false;
      this.showAlert('プランがオーダーされていません。','error')

    }
  }

  async getCurrentUser() {
    const currentUser = await this.angularFireAuth.currentUser;
    if (currentUser) {
      this.mail = currentUser.email;
    }
  }

  async getUserByMail(email:string) {
   const user = await this.userService.getUserByMail(email);
    if(user && user[0]){
      this.user = user[0];
    }
  }

  async cancelPlan(currentPlan:string)
  {
    console.log(this.currentSubId);
    this.isDisableButton = true;
    const headers = {'content-type': 'application/json'}
    const body = {
        "subId":this.currentSubId
    };
     await this.stripeService.delStripeSubscription(this.delSubUrl,body,headers).toPromise().then((data: any) => {
      this.userService.updateStripeSub('', this.mail, 'free', 'フリーアカウント').then(data=>{
        this.accountType = 'free';
        this.isDisableButton = false;
        this.currentSubId = '';
        this.showAlert(currentPlan+"プランをキャンセルしました。","success")

      })
    });

  }

  showAlert(msg:string,status:string){
    this.AlertService.clear()
    if(status =="success"){
      this.AlertService.success(msg);
    }else{
      this.AlertService.error(msg);
    }
  }
}
