import {Component} from '@angular/core';
import {AlertService, UserService, ValidationFormsService} from "@app/services";
import {Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  public currentUser: any;
  checkPass:any;
  formChangeUsername!: UntypedFormGroup;
  submitted = false;
  formErrors: any;
  email: any;
  username: any;
  user: any;
  showUsername: boolean = true;
  showFormChangeUsername: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    public angularFireAuth: AngularFireAuth,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    public vf: ValidationFormsService
  ) {}

   ngOnInit() {
     this.getCurrentUser().then();
     this.formChangeUsername = this.fb.group(
      {
        username: ['', [Validators.required, Validators.maxLength(this.vf.formRules.usernameMax)]],
      }
    );
    this.formErrors = this.vf.errorMessages;
  }

  logout() {
    this.userService.SignOut().then(res => {
      this.router.navigate(['login']).then();
    })
      .catch(error => {
        console.log('Something is wrong:', error.message);
      });
  }

  async getCurrentUser() {
    const currentUser = await this.angularFireAuth.currentUser;
    if (currentUser) {
      this.currentUser = currentUser;

      await this.emailValidate(currentUser.email)
      await this.getUserDb(currentUser.email)
    }
  }

 async deleteAccount() {
   await this.userService.updateStatusUser(this.user.uid, true).then(response => {
     this.userService.deleteAccount(this.user.id).then(rs=>{
       this.userService.SignOut().then(() => {
         this.router.navigate(['login']).then();
       });
     });
    }).catch(error => {
      this.userService.SignOut().then(() => {
        this.router.navigate(['login']).then();
      });
      return ({
        'message': 'Delete account failed: ' + error.message,
        'status': false,
      });
    })
  }
  async emailValidate(email: any) {
    const user = await this.angularFireAuth.fetchSignInMethodsForEmail(email);
    if (user) {
      user.forEach((vl,index) => {
        if(vl=='password'){
          this.checkPass = true;
        }
      });
    }
  }
  async getUserDb(email: any) {
    const user = await this.userService.getUserByMail(email);
    if (user && user[0]) {
      this.user = user[0];
    }
  }

  get attribute() {
    return this.formChangeUsername.controls;
  }

  onValidate() {
    this.submitted = true;
    return this.formChangeUsername.status === 'VALID';
  }

  changeUsername() {
    this.alertService.clear();
    this.username = this.formChangeUsername.value.username;
     this.email = this.currentUser.email;

    if (this.onValidate()) {
      try {
        this.userService.changeUsername(this.username, this.email).then(res => {
          window.location.reload();
        });
      } catch (e) {
        this.alertService.error('クレジットカードは追加できません。');
      }
    }
  }

  changeState() {
    this.showUsername = !this.showUsername;
    this.showFormChangeUsername = !this.showFormChangeUsername;
    this.formChangeUsername.reset();
    this.submitted = false;
  }
}
