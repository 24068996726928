import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserService } from '@app/services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user = await this.userService.userValue();
    if (user) {
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }}).then(r => {
      });
    }
    return false;
  }
}
