import {Component, OnInit} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/compat/firestore';
import {NotificationService, UserService, AlertService} from '@app/services';
import {Notification} from '@app/models/notification.model';
import {Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {Observable} from 'rxjs';

@Component({
  selector: 'app-noti',
  templateUrl: './noti.component.html',
  styleUrls: ['./noti.component.scss']
})

export class NotiComponent implements OnInit {
  notifications: Notification[] = [];
  email: any;
  id: any;
  notiData: any;
  isNewMessage: boolean = false;

  constructor(private db: AngularFirestore,
              private notificationService: NotificationService,
              private userService: UserService,
              private angularFireAuth: AngularFireAuth,
              private alertService: AlertService,
  ) {
    this.currentUser().then(data => {
    });

  }
  timeSince(date:any) {
    let time:any = new Date();
    var seconds = Math.floor((time-date)/1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + "年前";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + "ヶ月前";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + "日前";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "時間前";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "分前";
    }
    return "今";
  }
  ngOnInit(): void {
  }

  async currentUser() {
    const currentUser = await this.angularFireAuth.currentUser;
    if (currentUser) {
      this.email = currentUser.email;
      this.id = await this.userService.getDocId(this.email);
      this.notification();
    }
  }
  notification(){
    this.notificationService.getNotification(this.email, this.id)
      .subscribe((response) => {
          this.notifications = response.map(a => {
            if (a.type == 'modified') {
              this.isNewMessage = false;
            }
            const data = a.payload.doc.data() as Notification;
            data.timeAgo = this.timeSince(new Date(data.createdAt.seconds * 1000))
            return data;
          })
          if (response.length && this.isNewMessage) {
            this.showAlert('新着通知があります。');
          }
          this.isNewMessage = true;
        },
        (error) => {
          console.log(error);
        })
  }

  showAlert(msg: string){
    this.alertService.clear()
    this.alertService.success(msg);
  }
}
