import { Component, OnInit } from '@angular/core';
import {UserService, AlertService,ValidationFormsService} from '@app/services';
import {Router} from "@angular/router";
import { ValidatorFn, ValidationErrors, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {AngularFireAuth} from "@angular/fire/compat/auth";

export function confirmPasswordValidator(control: UntypedFormGroup): ValidationErrors | null {
  const password = control.get('password');
  const confirm = control.get('confirmPassword');
  return password?.value && password?.value === confirm?.value
    ? null
    : { passwordMismatch: true };
};
@Component({
  selector: 'app-register',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
  providers: [ValidationFormsService],

})
export class UpdatePasswordComponent implements OnInit {
  formUpdatePassword!: UntypedFormGroup;
  submitted = false;
  formErrors: any;
  checkPass: boolean = false;
  email: string = '';
  public currentUser: any;

  ngOnInit(): void {
    this.getCurrentUser().then();
  }

  constructor(private userService: UserService,
              private router: Router,
              private fb: UntypedFormBuilder,
              public vf: ValidationFormsService,
              private AlertService: AlertService,
              public angularFireAuth: AngularFireAuth,
  ) {
    this.formErrors = this.vf.errorMessages;
    this.createForm();

  }
  createForm() {
    this.formUpdatePassword = this.fb.group({
        oldPassword: ['', [Validators.required]],
        password: ['', [Validators.required,Validators.pattern("^(?=.*[A-Z])(?=.*[a-z])(?=.*[\\d])([A-Za-z\\d]{8,32})$")]],
        confirmPassword: ['', [Validators.required]],
      },
      { validators: confirmPasswordValidator }
    );
  }
  get f() {
    return this.formUpdatePassword.controls;
  }
  onValidate() {
    this.submitted = true;
    // stop here if form is invalid
    return this.formUpdatePassword.status === 'VALID';
  }

  updatePassword(message?: any) {
    this.AlertService.clear();

    if (this.onValidate()) {
      this.userService.updatePassword(this.formUpdatePassword.value.oldPassword, this.formUpdatePassword.value.password).then(res => {
        this.router.navigate(['/account']).then();
      })
        .catch(error => {
          this.AlertService.error('パスワードが無効です。');
        });
    }
  }

  async getCurrentUser() {
    const currentUser = await this.angularFireAuth.currentUser;
    if (currentUser) {
      this.currentUser = currentUser;
      await this.emailValidate(currentUser.email)
    }
  }

  async emailValidate(email: any) {
    const user = await this.angularFireAuth.fetchSignInMethodsForEmail(email);
    if (user) {
      user.forEach((vl,index) => {
        if(vl=='password') {
          this.checkPass = true;
        }
      });
    }
  }
}
