import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AlertService, NotificationService, UserService} from '@app/services';
import {Notification} from '@app/models/notification.model';
import {Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/compat/auth";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  notifications: Notification[] = [];
  email: any;
  id: any;
  currentPage: number = 0;
  perPage: number = 10;
  data: Array<any> = [];
  isDisableNextPage: boolean = false;
  isDisablePreviousPage: boolean = false;
  isOnePage: boolean = false;

  constructor(private db: AngularFirestore,
              private notificationService: NotificationService,
              private router: Router,
              private angularFireAuth: AngularFireAuth,
              private userService: UserService,
              private alertService: AlertService
  ) {
    this.currentUser().then(data=>{
      this.notificationService.getAll(this.email, this.id)
        .subscribe((response) => {
          const dataResponse = response.map(a => {
            const data = a.payload.doc.data() as Notification;
            data.timeAgo = this.timeSince(new Date(data.createdAt.seconds * 1000))
            data.id = a.payload.doc.id;
            return data;
          });
          this.data = [];
          for (let i = 0; i < dataResponse.length; i += this.perPage) {
            this.data.push(dataResponse.slice(i, i + this.perPage))
          }
            console.log(this.data.length)
          if (this.data.length == 1) {
            this.isOnePage = true;
          }
          this.notifications = this.data[this.currentPage]
          this.isDisablePreviousPage = true;
          },
          (error) =>{
            console.log(error);
          })

    });
  }

  timeSince(date: any) {
    let time:any = new Date();
    var seconds = Math.floor((time-date)/1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + "年前";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + "ヶ月前";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + "日前";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "時間前";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "分前";
    }
    return "今";
  }

  ngOnInit(): void {

  }

  async currentUser() {
    const currentUser = await this.angularFireAuth.currentUser;
    if (currentUser) {
      this.email = currentUser.email;
      this.id = await this.userService.getDocId(this.email);
    }
  }

  delete(id: any) {
    this.notificationService.delete(this.email, this.id, id).then(response => {
      this.showAlert("通知を削除しました。");

    });
  }

  showAlert(msg:string) {
    this.alertService.clear()
    this.alertService.success(msg);
  }

  readMark(id: any) {
    this.notificationService.readMark(this.email, this.id, id).then(response => {
    });
  }

  readMarkAll() {
    this.notifications.forEach((vl:Notification,index) => {
      if(!vl.isRead) {
          this.notificationService.readMark(this.email, this.id, vl.id).then(response => {
          });
        }
    });
  }

 nextPage() {
   this.isDisablePreviousPage = false;
   if (this.currentPage < (this.data.length - 1)) {
     this.currentPage++;
     this.notifications = this.data[this.currentPage];
   }
   if (this.currentPage == (this.data.length - 1)) {
     this.isDisableNextPage = true;
     this.notifications = this.data[this.currentPage];
   }
 }

  previousPage() {
    this.isDisableNextPage = false;
    if ((this.data.length - 1) >= this.currentPage) {
      this.currentPage--;
      this.notifications = this.data[this.currentPage];
    }
    if (this.currentPage == 0) {
      this.isDisablePreviousPage = true;
      this.notifications = this.data[this.currentPage];
    }
  }

  toggleReadMore(notification: Notification) {
    notification.isReadMore = !notification.isReadMore;
  }
}
