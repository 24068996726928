<!--<c-footer>-->
  <div>
    <a href="https://coreui.io/pro/angular/" target="_blank">CoreUI</a>
    <span> &copy; 2022 creativeLabs</span>
  </div>
  <div class="ms-auto">
    Powered by
    <a href="https://coreui.io/pro/angular" target="_blank">
      <span> CoreUI v4 for Angular</span>
    </a>
  </div>
<!--</c-footer>-->
