import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  constructor(private angularFirestore: AngularFirestore) {
  }

  getAll() {
    return this.angularFirestore.collection('prices', ref => ref.orderBy('sort')).valueChanges({idField: 'id'})
  }
}
