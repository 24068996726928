import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { ValidatorFn, ValidationErrors, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ValidationFormsService } from "../../../services/validate-form.service";
import {UserService, AlertService} from '@app/services';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {onAuthStateChanged} from "@firebase/auth";

@Component({
  selector: 'app-register',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [ValidationFormsService],

})
export class ForgotPasswordComponent implements OnInit {

  formForgotPassword!: UntypedFormGroup;
  submitted = false;
  formErrors: any;
  checkPass: boolean = false;
  ngOnInit(): void { }

  constructor(private userService: UserService,
              private router: Router,
              private fb: UntypedFormBuilder,
              public vf: ValidationFormsService,
              private AlertService: AlertService,
              private angularFireAuth: AngularFireAuth,
  ) {
    this.createForm();

  }
  createForm() {
    this.formForgotPassword = this.fb.group(
        {
          email: ['', [Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]],
        },
      );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.formForgotPassword.controls;
  }
  onValidate() {
    this.submitted = true;
    return this.formForgotPassword.status === 'VALID';
  }


 async forgotPassword() {
    this.AlertService.clear();
    const email = this.formForgotPassword.value.email;

    if (this.onValidate()) {
      await this.emailValidate(this.formForgotPassword.value.email).then();
      if(!this.checkPass){
        this.AlertService.error('このメールアドレスは登録されていません。');
            return;
      }
      this.userService.forgotPassword(this.formForgotPassword.value.email).then(res => {

        this.AlertService.success(`${email}へパスワードリセットのメールを送信しました。`);
        // this.router.navigate(['login']).then();
      }).catch(error => {
        this.AlertService.error('このメールアドレスは登録されていません。');

        });
    }
  }

  async emailValidate(email: string) {
    const user = await this.angularFireAuth.fetchSignInMethodsForEmail(email);
    if (user) {
      user.forEach((vl,index) => {
        if(vl=='password'){
          this.checkPass = true;
        }
      });
    }
  }
}
