import {Injectable} from '@angular/core';
import {Notification} from '@app/models/notification.model'
import {AngularFirestore} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private db: AngularFirestore) {
  }

  // Create notification
  async create(Notification: Notification) {
    await this.db.collection('messages').add({
      messageTitle: Notification.messageTitle,
      messageBody: Notification.messageBody,
      sendAll: Notification.sendAll,
      user_uid: Notification.user_uid,
    });
  }

  getAll(email:string, id:string) {
    return this.db.collection('users', ref => ref.where('email', '==', email))
      .doc(id).collection('messages', ref => ref
        .where('isDelete', '==', false)
        .orderBy('timePushMessage', 'desc'))
      .snapshotChanges();
  }

  getNotification(email:string, id:string) {
    return this.db.collection('users', ref => ref.where('email', '==', email))
      .doc(id).collection('messages', ref => ref
        .where('isDelete', '==', false)
        .orderBy('timePushMessage', 'desc')
        .limit(5))
      .snapshotChanges();
  }

  delete(email:string, id:string,messageId:string) {
    return this.db.collection('users',ref => ref.where('email','==',email))
      .doc(id).collection('messages').doc(messageId)
      .update({'isDelete':true});
  }

  readMark(email: string, id: string, messageId: string | undefined) {
    return this.db.collection('users', ref => ref.where('email', '==', email))
      .doc(id).collection('messages').doc(messageId)
      .update({'isRead': true});
  }

  nextPage(email: string, id: string, currentLastDocumentTime: any) {
    return this.db.collection('users',ref => ref.where('email','==',email))
      .doc(id).collection('messages', ref => ref
        .where('isDelete', '==', false)
        .orderBy('timePushMessage', 'desc')
        .startAfter(currentLastDocumentTime)
        .limit(10))
      .get();
  }
}
