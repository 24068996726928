<c-container class="min-vh-90">
  <c-row class="justify-content-between mb-5">
    <c-col [md]="6" [lg]="6" [xs]="3">
      <img cImg src="assets/svg/main-logo.svg" alt="main logo" />
    </c-col>
    <c-col [md]="6" [lg]="6" [xs]="9">
      <app-noti></app-noti>
    </c-col>
  </c-row>

  <c-row class="justify-content-center">
    <c-col md="5">
      <div class="text-center mb-5">
        <img cImg src="assets/svg/update-password-image.svg" alt="update password image" />
      </div>

<!--        <form cForm class="mb-4">-->
        <form cForm [formGroup]="formUpdatePassword" novalidate (ngSubmit)="updatePassword()" class="needs-validation">

        <h1 class="text-center mb-4">パスワード変更</h1>

        <c-input-group class="mb-3 d-flex align-items-center custom-input">
          <label for="oldPassword" cLabel class="mx-3 ms-3">
            <img cImg src="assets/svg/icon-password.svg" alt="old password" />
          </label>
          <input
            name="oldPassword"
            id="oldPassword"
            formControlName="oldPassword"
            class="ps-0 px-0"
            type="password"
            autoComplete="current-password" cFormControl placeholder="現在のパスワード "
            [ngClass]="{ 'is-invalid': (submitted || f['oldPassword'].touched) && f['oldPassword'].invalid, 'is-valid': f['oldPassword'].touched && f['oldPassword'].valid  }"
          />
        </c-input-group>
          <div *ngIf="submitted && f['oldPassword'].invalid" class="invalid-feedback">
            <div *ngIf=" f['oldPassword'].errors && f['oldPassword'].errors['required']">{{formErrors.password.required}}</div>
          </div>
        <c-input-group class="mb-3 d-flex align-items-center custom-input">
          <label for="password" cLabel class="mx-3 ms-3">
            <img cImg src="assets/svg/icon-password.svg" alt="old password" />
          </label>
          <input
            formControlName="password"
            name="password"
            id="password"
            class="ps-0 px-0"
            type="password"
            autoComplete="current-password" cFormControl placeholder="新しいパスワード"
            [ngClass]="{ 'is-invalid': (submitted || f['password'].touched) && f['password'].invalid, 'is-valid': f['password'].touched && f['password'].valid  }"

          />
        </c-input-group>
          <div *ngIf="submitted && f['password'].invalid" class="invalid-feedback">
            <div *ngIf=" f['password'].errors && f['password'].errors['required']">{{formErrors.password.required}}</div>
          </div>
        <c-input-group class="mb-3 d-flex align-items-center custom-input">
          <label for="confirmPassword" cLabel class="mx-3 ms-3">
            <img cImg src="assets/svg/icon-password.svg" alt="old password" />
          </label>
          <input
            name="confirmPassword"
            id="confirmPassword"
            formControlName="confirmPassword"
            class="ps-0 px-0"
            type="password"
            autoComplete="current-password" cFormControl placeholder="新しいパスワード（確認）"
            [ngClass]="{ 'is-invalid': submitted && formUpdatePassword.errors, 'is-valid': f['confirmPassword'].touched && !formUpdatePassword.errors }"
          />
        </c-input-group>
          <div *ngIf="submitted && formUpdatePassword.errors" class="invalid-feedback">
            <div *ngIf="formUpdatePassword.errors['passwordMismatch']">{{formErrors.confirmPassword.passwordMismatch}}</div>
          </div>
        <div class="d-grid">
          <button cButton class="px-4 btn-orange width-100" type="submit">変更</button>
        </div>
      </form>
    </c-col>
  </c-row>
</c-container>
