<c-col class="d-flex align-items-center">
  <c-col [md]="4" [xs]=4 class="d-flex justify-content-center">
    <a href="#/account">
      <button class="btn-notification btn-link">
        <img cImg src="assets/svg/profile-black.svg" alt="profile" />
        <span class="ms-2">アカウント</span>
      </button>
    </a>
  </c-col>
  <c-col [md]="4" [xs]=4 class="d-flex justify-content-center">
    <c-dropdown direction="dropstart">
      <button cDropdownToggle class="btn-notification btn-link">
        <img cImg src="assets/svg/notification-orange.svg" alt="notification" />
        <span class="ms-2">通知</span>
      </button>
      <div cDropdownMenu class="p-4" [ngStyle]="{ 'minWidth.px': 450, 'maxWidth.px': 450 }">
        <c-col *ngFor="let notification of notifications; index as i" class="list-notification">
          <c-col class="d-flex align-items-center align-items-center">
            <c-col [md]="2" [xs]=2 class="d-flex justify-content-end">
              <img *ngIf="!notification.isRead" cImg class="me-2" src="assets/svg/red-dot.svg" alt="red dot" />
              <img cImg src="assets/svg/password-reset.svg" alt="password reset image" />
            </c-col>
            <c-col [md]="8" [xs]="8" class="px-3">
              <h5 class="mb-1 long-text">{{notification.messageTitle}}</h5>
              <p class="mb-0 long-text">{{notification.messageBody}}</p>
            </c-col>
            <c-col [md]="2" [xs]="2" class="d-flex justify-content-end">
              <p class="mb-0 text-orange">{{notification.timeAgo}}</p>
            </c-col>
          </c-col>
          <hr cDropdownDivider>
        </c-col>
        <c-col class="text-center mt-3">
          <a href="#/notification" class="btn-link text-orange"><strong>すべてを見る</strong></a>
        </c-col>
      </div>
    </c-dropdown>
  </c-col>
  <c-col [md]="4" [xs]="4" class="d-flex justify-content-end">
    <a href="#/purchase">
      <button cButton class="btn-purchase">購入</button>
    </a>
  </c-col>
</c-col>
