import {Component, OnInit} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {OAuthProvider} from "@firebase/auth";
import {UserService, AlertService} from '@app/services';
import {Router} from "@angular/router";
import {getAuth} from "@angular/fire/auth";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form!: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    public angularFireAuth: AngularFireAuth,
    private AlertService: AlertService,
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,) {
  }

  get f() {
    // @ts-ignore
    return this.form.controls;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', Validators.required, Validators.email],
      password: ['', Validators.required]
    });
    this.getAccountUser().then();
  }

  Login() {
    this.submitted = true;

    this.AlertService.clear();

    if (this.form.invalid) {
      return;
    }

    const auth = getAuth();
    auth.languageCode = 'ja';
    this.angularFireAuth.signInWithEmailAndPassword(this.f['email'].value, this.f['password'].value)
      .then(res => {
        this.router.navigate(['account']).then();
      })
      .catch(error => {
        this.AlertService.error('ログインに失敗しました。');
      });
  }

  async SignInGoogle() {
    this.AlertService.clear();
    this.userService.SignInGoogle().then().catch(
      error=>{
        this.AlertService.error('ログインできませんでした。');
      }
    );
  }

  async getAccountUser(){
    let user = await this.userService.userValue();
    if (user) {
      this.router.navigate(['account']).then();
    }
  }

  async SignInApple() {
    await this.userService.SignInApple().then().catch(
      error=>{
        this.AlertService.error('ログインできませんでした。');
      }
    );
  }

  async getCurrentUser() {
    const currentUser = await this.angularFireAuth.currentUser;
    if (currentUser) {
      console.log('currentUser', currentUser.displayName, currentUser);
    }
  }
}
