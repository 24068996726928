import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from './icons/icon-subset';
import { Title } from '@angular/platform-browser';
import {MessagingService} from '@app/services';
import { HttpClient } from '@angular/common/http';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  postId:any;
  title = 'CoreUI Free Angular Admin Template';
  message:any;
  constructor(
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService,
    private messagingService: MessagingService,
    private http: HttpClient
  ) {
    titleService.setTitle(this.title);
    // iconSet singleton
    iconSetService.icons = { ...iconSubset };
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
    this.messagingService.requestPermission()
    this.messagingService.receiveMessaging()
    this.message = this.messagingService.currentMessage;
  }
}
