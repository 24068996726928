<c-container class="min-vh-90">
  <c-row class="justify-content-center">
    <c-col [md]="8" [lg]="5">
      <c-card-group>
        <c-card class="p-4 background-orange border-0">
          <c-card-body>
            <div class="text-center mb-5">
              <img cImg src="assets/svg/register-image.svg" alt="login image" />
            </div>
            <form
              cForm
              class="mb-4 needs-validation"
              novalidate
              (ngSubmit)="register()"
              [formGroup]="formRegister"
            >
              <h1 class="text-center mb-4">サインアップ</h1>
              <c-input-group class="mb-3 d-flex align-items-center custom-input">
                <label for="email" cLabel class="mx-3 ms-3">
                  <img cImg src="assets/svg/icon-email.svg" alt="email" />
                </label>
                <input
                  cFormControl
                  required
                  class="ps-0 px-0"
                  formControlName="email"
                  id="email"
                  type="email"
                  autoComplete="email"
                  placeholder="メールアドレス"
                  [ngClass]="{ 'is-invalid': (submitted || attribute['email'].touched) && attribute['email'].invalid, 'is-valid': attribute['email'].touched && attribute['email'].valid  }"
                />
              </c-input-group>
              <c-col *ngIf="submitted && attribute['email'].invalid" class="invalid-feedback mb-2">
                <c-col *ngIf=" attribute['email'].errors && attribute['email'].errors['required']">{{formErrors.email.required}}</c-col>
                <c-col *ngIf="attribute['email'].errors && attribute['email'].errors['pattern']">{{formErrors.email.email}}</c-col>
              </c-col>

              <c-input-group class="mb-3 d-flex align-items-center custom-input">
                <label for="password" cLabel class="mx-3 ms-3">
                  <img cImg src="assets/svg/icon-password.svg" alt="password" />
                </label>
                <input
                  cFormControl
                  required
                  class="ps-0 px-0"
                  formControlName="password"
                  id="password"
                  type="password"
                  autoComplete="password"
                  placeholder="パスワード"
                  [ngClass]="{ 'is-invalid': (submitted || attribute['password'].touched) && attribute['password'].invalid, 'is-valid': attribute['password'].touched && attribute['password'].valid  }"
                />
              </c-input-group>
              <c-col *ngIf="submitted && attribute['password'].invalid" class="invalid-feedback mb-2">
                <c-col *ngIf=" attribute['password'].errors && attribute['password'].errors['required']">{{formErrors.password.required}}</c-col>
                <c-col *ngIf="attribute['password'].errors && attribute['password'].errors['pattern']">{{formErrors.password.pattern}}</c-col>
              </c-col>

              <c-col>
                <button cButton class="px-4 btn-orange width-100" type="submit">サインアップ</button>
              </c-col>
            </form>
            <br>
            <c-row>
              <c-col [md]="8" class="d-flex align-items-center">
                <p class="mb-0">ソーシャルログイン</p>
              </c-col>
              <c-col [md]="4">
                <div class="group-btn">
                  <button class="btn-social">
                    <img src="./assets/img/login/login-google.png" alt="logo google">
                  </button>
                  <button class="btn-social">
                    <img src="./assets/img/login/login-apple.png" alt="logo apple">
                  </button>
                </div>
              </c-col>
            </c-row>
            <br>
            <c-row>
              <c-col [md]="12">
                <p class="text-center mb-0 line-center">
                  または
                </p>
              </c-col>
            </c-row>
            <br>

            <c-row>
              <c-col>
                <p class="text-center">すでにアカウントをお持ちですか？
                  <a class="text-orange btn-link" href="#/login">ログイン</a>
                </p>
              </c-col>
            </c-row>
          </c-card-body>
        </c-card>
      </c-card-group>
    </c-col>
  </c-row>
</c-container>
