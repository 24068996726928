import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationFormsService {

  errorMessages: any;

  formRules = {
    nonEmpty: '^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$',
    usernameMin: 1,
    usernameMax: 255,
    passwordMin: 6,
    passwordPattern: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,}',
    emailPattern:'^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  };

  formErrors = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    accept: false,
  };

  constructor() {
    this.errorMessages = {
      firstName: {
        required: 'First name is required',
      },
      lastName: {
        required: 'Last name is required',
      },
      username: {
        required: 'ユーザー名をご入力ください。',
        maxLength: `ユーザー名は${this.formRules.usernameMin}文字以上${this.formRules.usernameMax}文字以下でご入力ください。`,
        pattern: 'Must contain letters and/or numbers, no trailing spaces'
      },
      email: {
        required: 'メールアドレスをご入力ください。',
        email: '正しい形式のメールアドレスをご入力ください。',
        inCorrect: '正しい形式のメールアドレスをご入力ください。',
      },
      password: {
        required: 'パスワードをご入力ください。',
        pattern: ' 半角大小英数字をそれぞれ1種類以上含む8文字以上32文字以下でご入力ください。',
        minLength: `Password must be at least ${this.formRules.passwordMin} characters`
      },
      confirmPassword: {
        required: '確認用のパスワードをご入力ください。',
        passwordMismatch: '入力されたパスワードが新しいパスワードと一致しません。もう一度ご入力ください。'
      },
      accept: {
        requiredTrue: 'You have to accept our Terms and Conditions'
      },
      cardNumber: {
        required: '内容をご入力ください。'
      },
      cardCVC: {
        required: '内容をご入力ください。'
      },
      exp_month: {
        required: '内容をご入力ください。'
      },
      exp_year: {
        required: '内容をご入力ください。'
      },
    };
  }
}
