<c-container class="min-vh-90">
  <c-row class="justify-content-center mb-4">
    <c-col [md]="10">
      <h3>アカウント</h3>
    </c-col>
  </c-row>

  <c-row class="justify-content-center">
    <c-col [md]="10">
      <c-card>
        <c-card-body class="p-4">
          <c-row class="mb-4">
            <c-col [md]="12">
              <h5>サポート</h5>
            </c-col>
            <c-col [md]="12" class="d-flex">
              <p>noreply@pacenotes.io</p><p>までメールで問い合わせください。</p>
            </c-col>
          </c-row>
          <c-row>
            <c-col [md]="12" class="d-flex justify-content-between">
              <h5>アカウント</h5>
              <a href="#/login">
                <button cButton class="btn-redirect-login">ログイン</button>
              </a>
            </c-col>
          </c-row>
        </c-card-body>
      </c-card>
    </c-col>
  </c-row>
</c-container>
