// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyAFAjgQivgf2lw624gVQPcqQykMs9sdKYA",
    authDomain: "generalpurpose-361522.firebaseapp.com",
    projectId: "generalpurpose-361522",
    storageBucket: "generalpurpose-361522.appspot.com",
    messagingSenderId: "616406667628",
    appId: "1:616406667628:web:4eb0bc2a91675e74fa6349",
    measurementId: "G-T69EPYHK4Y"
  },
  register_url_stripe:"https://us-central1-generalpurpose-361522.cloudfunctions.net/api/stripe",
  register_url_stripe_subscription:"https://us-central1-generalpurpose-361522.cloudfunctions.net/api/stripe-subscription",
  del_url_stripe_subscription:"https://us-central1-generalpurpose-361522.cloudfunctions.net/api/del-stripe-subscription",
  list_payment_methods:"https://us-central1-generalpurpose-361522.cloudfunctions.net/api/list-payment-method",
  add_payment_methods:"https://us-central1-generalpurpose-361522.cloudfunctions.net/api/create-payment-method",
  remove_payment_methods:"https://us-central1-generalpurpose-361522.cloudfunctions.net/api/detach-method-default",
  customer_stripe:"https://us-central1-generalpurpose-361522.cloudfunctions.net/api/get-customer/",
  set_default_payment_methods:"https://us-central1-generalpurpose-361522.cloudfunctions.net/api/set-payment-method-default",
};
