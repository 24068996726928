<c-container class="min-vh-90">
  <c-row class="justify-content-between mb-5">
    <c-col [md]="6" [lg]="6" [xs]="3">
      <img cImg src="assets/svg/main-logo.svg" alt="main logo" />
    </c-col>
    <c-col [md]="6" [lg]="6" [xs]="9">
      <app-noti></app-noti>
    </c-col>
  </c-row>

  <c-row class="d-flex justify-content-center">
    <c-col [md]="10" class="mb-4 d-flex justify-content-between align-items-center">
      <c-col>
        <h4 class="mb-0">クレジットカード情報</h4>
      </c-col>
      <c-col class="d-flex justify-content-end">
        <a href="#/payment">
        <button class="btn-payment-method btn-primary">クレジットカードを登録</button>
        </a>
      </c-col>
    </c-col>
    <c-col [md]="10">
      <c-card>

          <c-col  class="list-payment-method" *ngFor="let item of listPaymentMethod; index as i" >
            <c-col class="mb-3 d-flex ">
              <c-col [lg]="7" [md]="7" [sm]="7" [xs]="7" class="ps-3 flex-grow-1">
                <p class="mb-1 mt-2" >****-****-****-{{item.card.last4}} <span *ngIf="default_payment_method==item.id" class="default-payment">デフォルト</span> </p>
                <p class="mb-0">有効期限: {{item.card.exp_year}}/{{item.card.exp_month < 10?"0"+item.card.exp_month:item.card.exp_month}}</p>
              </c-col>
                <c-col class="mt-2 list-action">

                  <button (click)="setDefaultPaymentMethod(item.id)" *ngIf="default_payment_method != item.id" class="btn-payment-method btn-primary">デフォルトにする</button>
                  <button *ngIf="default_payment_method != item.id" class="btn-notification-action" (click)="toggle(item.id)">
                    <img cImg src="assets/svg/trash.svg" alt="notification more" />
                  </button>
                </c-col>
            </c-col>
          </c-col>
      </c-card>
    </c-col>
  </c-row>
  <c-modal id="modal-remove" [visible]="visible" (visibleChange)="handleChange($event)">
    <c-modal-header>
      <h5 cModalTitle>カードを削除しますか？</h5>
      <button (click)="closeModal()" cButtonClose></button>
    </c-modal-header>

      <c-modal-body>

      </c-modal-body>
      <c-modal-footer>
        <button (click)="closeModal()" cButton color="secondary">キャンセル</button>
        <button [disabled]="disable"  cButton color="primary" (click)="removePaymentMethod()">削除</button>
      </c-modal-footer>

  </c-modal>
</c-container>
